import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Seo from "../../components/Seo"

const ProjectPage = ({ data: { previous, next, project } }) => {
  return (
    <React.Fragment>
      <Seo
        title={project.ProjectGQL.title}
        description={""}
        image={
          project.ProjectGQL.thumbnailImage.localFile.childImageSharp
            .gatsbyImageData.images.fallback.src
        }
      />
      <Navbar />
      <main className="marginTopNavbar">
        <section className="project-header">
          <GatsbyImage
            draggable="false"
            imgClassName="project-header__image"
            alt={project.ProjectGQL.title}
            image={
              project.ProjectGQL.thumbnailImage.localFile.childImageSharp
                .gatsbyImageData
            }
          />
          <div className="main-container">
            <h1 className="project-header__title">
              {parse(project.ProjectGQL.title)}
            </h1>
          </div>
        </section>

        <div className="main-container">
          <div className="grid-8-col">
            <div className="col-3 ">
              <h1 className="sticky">EC Grant Information</h1>
            </div>

            <div className="col-4">
              {parse(project.ProjectGQL.ecGrantInformation)}
            </div>
          </div>
          <div className="horizontal-line" />
          <div className="grid-8-col">
            <div className="col-3 ">
              <h1 className="sticky">Overview</h1>
            </div>

            <div className="col-4">{parse(project.ProjectGQL.overview)}</div>
          </div>
          <div className="horizontal-line" />
          <div className="grid-8-col">
            <div className="col-3 ">
              <h1 className="sticky">Objectives</h1>
            </div>

            <div className="col-4">{parse(project.ProjectGQL.objectives)}</div>
          </div>
          <div className="horizontal-line" />
          <div className="grid-8-col">
            <div className="col-3 ">
              <h1 className="sticky">Expected Impacts</h1>
            </div>

            <div className="col-4">
              {parse(project.ProjectGQL.expectedImpacts)}
            </div>
          </div>

          <div className="horizontal-line" />
          <div className="grid-8-col">
            <div className="col-3 ">
              <h1 className="sticky">Team</h1>
            </div>
            <div className="col-4"></div>

            <div className="team__items col-8 grid-8-col" style={{ margin: 0 }}>
              {project.ProjectGQL.team.map((member, memberIndex) => {
                return (
                  <div className="col-2" key={memberIndex}>
                    <GatsbyImage
                      className="col-2 team__items__image"
                      alt={member.MemberGQL.name}
                      image={
                        member.MemberGQL.profileImage.localFile.childImageSharp
                          .gatsbyImageData
                      }
                    />
                    <h2 className="team__items__title">
                      {member.MemberGQL.name}
                    </h2>
                    <p className="team__items__role">
                      {member.MemberGQL.role.toUpperCase()}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
          {/* <div className="horizontal-line" /> */}

          {/* <div className="grid-8-col">
            <div className="col-3 ">
              <h1 className="sticky">Collaborators</h1>
            </div>
            <div className="col-4"></div>

            <div className="group-list__list col-8 grid-8-col">
              <p className="group-list__list__subtitle col-1">People</p>
              <div className=" col-7">
                {project.ProjectGQL.collaborators.map(
                  (collaborator, collIndex) => {
                    const name = collaborator.CollaboratorGQL.name
                    const link = collaborator.CollaboratorGQL.link
                    const type = collaborator.CollaboratorGQL.typology

                    return (
                      <div
                        className="group-list__list__item"
                        key={collIndex}
                        style={{
                          display:
                            type === "Companies & Institutions" && "none",
                        }}
                      >
                        {link == null ? (
                          <h2>{name}</h2>
                        ) : (
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h2>{name}↗</h2>
                          </a>
                        )}
                      </div>
                    )
                  }
                )}
              </div>

              <p className="group-list__list__subtitle col-1">
                Companies & Institutions
              </p>
              <div className=" col-7">
                {project.ProjectGQL.collaborators.map(
                  (collaborator, collIndex) => {
                    const name = collaborator.CollaboratorGQL.name
                    const link = collaborator.CollaboratorGQL.link
                    const type = collaborator.CollaboratorGQL.typology

                    return (
                      <div
                        className="group-list__list__item"
                        key={collIndex}
                        style={{ display: type === "People" && "none" }}
                      >
                        {link == null ? (
                          <h2>{name}</h2>
                        ) : (
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h2>{name}↗</h2>
                          </a>
                        )}
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </div> */}
        </div>

        <div className="horizontal-line" />
        <div className="main-container">
          <nav className="link-pages-nav">
            <ul className="link-pages-nav__ul">
              <li className="link-pages-nav__ul__left">
                {previous && (
                  <>
                    <span>Previous</span> <br />
                    <Link to={previous.uri} rel="prev">
                      {parse(previous.title)}
                    </Link>
                  </>
                )}
              </li>
              <li className="link-pages-nav__ul__center">
                <Link to="/research/#projects">More Projects</Link>
              </li>
              <li className="link-pages-nav__ul__right">
                {next && (
                  <>
                    <span>Next</span> <br />
                    <Link to={next.uri} rel="next">
                      {parse(next.title)}
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query ProjectById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    project: wpProject(id: { eq: $id }) {
      id
      date(formatString: "MMMM DD, YYYY")
      ProjectGQL {
        title
        thumbnailImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        team {
          ... on WpMember {
            MemberGQL {
              role
              name
              profileImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0.81)
                  }
                }
              }
            }
          }
        }
        shortDescription
        overview
        objectives
        expectedImpacts
        ecGrantInformation
      }
    }
    previous: wpProject(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpProject(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
